<template lang='pug'>
//- .question-screen(:style='bgImage')
.question-screen
    .topbar
        .replay(@click='handleView')
            img(src='/imgs/app/tt-icon-replay.svg')
        .score
            span {{ score }}
            | of 7
    header
        p(v-if='!result' v-html='data.text' :class='{fade: fade}')
        span.result(v-else)
            h1(v-html='correct ? "Great Job!" : "Not Quite"')
            p(v-html='data.response')
    section(:class='[{fade: fade}, {result}]')
        button(v-for='(answer, i) in data.answers' v-html='answer' @click='handleAnswer($event)' :id='i + 1' :style='{order: order[i]}')
    footer(:class='{result}')
        button( @click='handleQuestion' v-if='result') next
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    mounted() {
        this.$store.commit('setQuestions')
    },
    computed: {
        ...mapGetters(['questions']),
        ...mapState(['view', 'question', 'result', 'score', 'fade', 'correct']),
        order() { return [1, 2, 3, 4].sort(() => Math.random() - 0.5) },
        data() { return this.questions[this.question] },
        // bgImage() { return { backgroundImage: `url(/imgs/app/ayh-bg-q${this.question + 1}.jpg)` } }
    },
    methods: {
        handleView() {
            this.$store.commit('handleView', 'video')
        },
        handleAnswer(event) {
            const el = event.target
            let point = this.data.correct === +el.id
            point ? el.classList.add('correct') : el.classList.add('incorrect')
            this.$store.commit('handleFreeze', { val: true, time: 2500 })
            setTimeout(() => {
                this.$store.commit('handleFade')
                this.$store.commit('handleAnswer', point)
            }, 1450)
            // 
            setTimeout(() => {
                this.$store.commit('handleResult')
            }, 2250)
        },
        handleQuestion() {
            this.$store.commit('handleQuestion')
            let els = this.$el.querySelectorAll('button')
            els.forEach((el) => { el.classList.remove('correct', 'incorrect') })
            this.$store.commit('handleFreeze', { val: true, time: 1500 })
            this.$store.commit('handleResult')
            setTimeout(() => {
                this.$store.commit('handleFade')
            }, 750)
        }
    }
}
</script>

<style lang='sass' scoped>
.question-screen
    @include flex-center
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: space-between
    background-color: $sho-wrong
    background-size: cover
    background-position: center
    &:before
        @include shadow3
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    .topbar
        display: flex
        justify-content: space-between
        align-items: center
        position: absolute
        width: 88%
        height: 15%
        z-index: 10
        .replay, .score
            display: flex
            justify-content: center
            align-items: center
            width: 18%
            height: 58px
            background-color: rgb(115 115 115 / 90%)
            border-radius: 28px
        .replay
            cursor: pointer
            img
                width: 80%
                opacity: 1
                transition: opacity 0.25s
            &:hover
                img
                    opacity: 0.8
        .score
            font-family: ff-din-web,Calibri,Arial,sans-serif
            font-weight: 600
            font-size: 3rem
            padding-top: 0.7rem
            span
                color: #3ec634
                padding-right: 0.5rem
                font-size: 3.35rem
                font-weight: 700
    *.fade
        transition: 1s
        opacity: 0
    header, section, footer
        z-index: 3
    header
        display: flex
        width: 75%
        height: 100%
        justify-content: center
        justify-self: center
        align-items: center
        p
            font-size: 3rem
            line-height: 1.25
            letter-spacing: 0.00025em
            color: white
            text-align: center
            margin-top: 17%
            margin-bottom: -2%
        .result
            display: block
            padding: 0.5rem 1rem
            width: 70%
            margin-top: 120px
            h1
                display: block
                background: rgb(0 0 0)
                padding: 1rem
                font-weight: 500
                text-transform: uppercase
            p
                margin-top: 2rem
                margin-bottom: -2rem
    section
        flex-flow: row wrap
        justify-content: center
        display: flex
        width: 90%
        padding: 2.5rem 1rem
        transition: opacity 1s
        button
            position: relative
            width: 48%
            height: 78px
            max-width: 100%
            margin: 1%
            padding: 0.5rem 1.5rem 0 1.5rem
            text-transform: none
            letter-spacing: 0.05rem
            line-height: 1.15
            font-size: 2rem
            background-color: rgb(0 0 0)
            border: 3px solid
            border-color: transparent
            span
                display: block
                color: red
        &.result
            pointer-events: none
            height: 0
    footer
        min-height: 50px
        &.result
            min-height: 150px
            width: 80%
            button
                position: relative
                width: 38%
                height: 58px
                max-width: 100%
                margin: 1%
                padding: 0.5rem 1.5rem 0 1.5rem
                letter-spacing: 0.05rem
                line-height: 1.15
                font-size: 2.5rem
                background-color: rgb(255 255 255)
                color: black
                cursor: pointer
                opacity: 1
                transition: opacity 0.25s
                &:hover
                    opacity: 0.9
                span
                    display: block
                    color: red
</style>