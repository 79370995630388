import Vue from 'vue'
import Vuex from 'vuex'
import data from '../data/index.yml'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        data,
        view: 'intro',
        play: '',
        score: 0,
        question: 0,
        questionBank: [],
        result: false,
        correct: false,
        vidProgress: 0,
        freeze: false,
        fade: false
    },
    getters: {
        intro: state => state.data.introScreen,
        howto: state => state.data.howtoScreen,
        result: state => state.data.results,
        questions: state => state.questionBank
    },
    mutations: {
        handleView(state, val) {
            switch (val) {
                case 'intro':
                case 'video':
                case 'howto':
                case 'question':
                case 'result':
                    state.view = val
                    break
                case 'form':
                    state.view = 'form'
                    break
                case 'restart':
                    state.data = data
                    state.view = 'howto',
                        state.play = '',
                        state.score = 0,
                        state.question = 0,
                        state.questionBank = [],
                        state.result = false
                        state.correct = false
                        state.freeze = false
                        state.fade = false
                    break
            }
        },
        handlePlay(state, val) {
            state.play = val
        },
        handleFade(state) {
            state.fade = !state.fade
        },
        handleResult(state) {
            state.result = !state.result
        },
        handleAnswer(state, val) {
            state.score <= 7 && val && (state.score++, state.correct = true)
        },
        handleQuestion(state) {
            state.question === 6
                ? state.view = 'result'
                : (state.question++, state.correct = false)
        },
        setQuestions(state) {
            const getRandom = () => [...Array(7).keys()].sort(() => Math.random() - 0.5)
            let arr = []
            let random = getRandom()
            random.forEach(i => arr = [...arr, state.data.questions[i]])
            !state.questionBank.length && (state.questionBank = [...arr.slice(0, 7)])
        },
        setVidProgress(state, val) {
            state.vidProgress = val
        },
        handleFreeze(state, { val, time }) {
            state.freeze = val
            setTimeout(() => { state.freeze = !val }, time)
        },
        preloadImages(state, val) {
            let dir = '/imgs/'
            let imgs = []
            val.forEach(img => (imgs = [...imgs, `${dir}${img}`]))
            imgs.forEach(path => (new Image().src = path))
        }
    },
    actions: {}
})