<template>
    <div style="">
        <div class="intro top-zero text-3xl" v-if="confirmation">
            <div style="max-width: 1100px; min-height: 460px;"
                class="flex flex-col h-full justify-center mx-auto py-6 text-3xl text-white z-20 bgblack">

                <h1 class="font-black mb-8 mt-8 text-5xl text-6xl mx-auto text-center">
                    You've Been Entered to Win!
                </h1>
                <div class="flex flex-row">
                    <div class="w-full">
                        <h2 class="mb-8 mt-8 sm:text-4xl lg:text-5xl px-8 mx-auto text-center ">
                            <span v-if="this.client == 'comcast'">Congratulations, you’ve been entered to win up to a
                                $50
                                eGift
                                Card.</span>
                            <span v-else>Congratulations, you’ve been entered to win up to $125 in eGift Cards.</span>
                        </h2>
                        <div class="mb-10 text-4xl max-w-max px-8 mx-auto text-center">Don’t forget to come back in November
                            for
                            TRIVIA TRAILERS: THE CURSE, featuring Oscar-winner Emma Stone and critically acclaimed
                            writer/comedian Nathan Fiedler.
                        </div>
                        <h3 class="mb-8 mt-8 sm:text-3xl lg:text-4xl px-8 mx-auto text-center ">
                            NOW TAKE A SHORT SURVEY FOR ANOTHER CHANCE TO WIN!
                        </h3>
                    </div>
                    <!-- <div class="w-4/12 flex justify-center align-center">
                        <img src="/imgs/app/ayh-gif-7.gif" class="pr-20 mr-20 mb-20 mt-5">
                    </div> -->
                </div>

                <div class="flex max-w-5xl mx-auto w-full justify-around">

                    <button class="btn-teal flex items-center justify-around text-center text-white uppercase text-4xl"
                        @click="playAgain()">Play Again</button>
                    <button class="btn-red flex items-center justify-around text-center text-white uppercase text-4xl"
                        @click="openSurvey()">TAKE THE SURVEY</button>
                    <!-- <button class="btn-red flex items-center justify-around text-center text-white uppercase text-4xl"
                        @click="goSho()">EXPLORE SHOPLACE</button> -->
                </div>
                <footer>
                    <p>Watch the premiere of the epic mini-series FELLOW TRAVELERS – October 27!</p>
                </footer>
            </div>
        </div>
        <div v-else style="max-width: 1100px;" class="z-20 h-full py-6 mx-auto text-3xl text-white bgblack">
            <!-- <div class="" v-if="this.$root.email !== ''">

            </div> -->
            <div class="">
                <div class="form-wrap mt-6">
                    <div class="mb-3">
                        <div class="flex w-11/12 mx-auto">
                            <div class="mx-auto">
                                <h3 class="h3 text-4xl">Register for the <strong class="text-show-red">Trivia Trailers:
                                        Fellow Travelers </strong>Promotion</h3>
                            </div>
                        </div>
                        <!-- <div class="w-12/12 mx-auto text-center">
                                    <h5 class='h4 text-4xl' id='code'>CONFIRMATION CODE: <span class="text-show-teal font-bold inline">{{ randomCode }}</span></h5>
                                </div> -->
                    </div>
                    <div>
                        <p id="error-text"></p>
                    </div>
                    <form action="#" @submit.stop.prevent="sendData" class="text-left">
                        <div class="w-11/12 flex mx-auto">
                            <div class="w-1/2">
                                <h2
                                    class="section-label class='text-2xl mb-1 block mt-2' font-bold mb-2 mt-2 text-4xl text-show-teal">
                                    Employee Info</h2>
                                <div class="flex">
                                    <div class="w-1/2 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="agentFirst">Your First Name</label>
                                        <input type="text" name="agentFirst" id="agentFirst"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="Agent First">
                                    </div>
                                    <div class="w-1/2 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="agentLast">Your Last Name</label>
                                        <input type="text" name="agentLast" id="agentLast"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="Agent Last">
                                    </div>
                                </div>

                                <div class="flex mt-6">
                                    <div class="w-3/5 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="empEmail">Your Work Email</label>
                                        <input type="text" name="empEmail" id="empEmail"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="name@company.com">
                                    </div>
                                    <div class="w-2/5 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="empDept">Department</label>
                                        <input type="text" name="empDept" id="empDept"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="Department">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/2">
                                <h2
                                    class="section-label class='text-2xl mb-1 block mt-2' font-bold mb-2 mt-2 text-4xl text-show-teal">
                                    Supervisor Info</h2>
                                <div class="flex">
                                    <div class="w-1/2 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="superFirst">Supervisor First
                                            Name</label>
                                        <input type="text" name="superFirst" id="superFirst"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="Supervisor First">
                                    </div>
                                    <div class="w-1/2 mx-3">
                                        <label class="text-2xl mb-1 block mt-2" for="superLast">Supervisor Last
                                            Name</label>
                                        <input type="text" name="superLast" id="superLast"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="Supervisor Last">
                                    </div>
                                </div>
                                <div class="flex mx-3 mt-6">
                                    <div class="w-full">
                                        <label class="text-2xl mb-1 block mt-2" for="superEmail">Supervisor Work
                                            Email</label>
                                        <input type="text" name="superEmail" id="supEmail"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl"
                                            placeholder="supervisor@company.com">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ROW// -->

                        <div class="w-11/12 mx-auto">
                            <hr class="w-1/2 mx-auto my-8" style="border-color:#2b8895;">
                            <h2
                                class="section-label class='text-2xl mb-1 block mt-2' font-bold mb-2 mt-2 text-4xl text-show-teal">
                                Call Center Location</h2>
                            <p class='form-p leading-normal m-b-1 m-l-1 mb-1 ml-1 text-2xl'>You must select your company
                                first,<em> then</em> your state, then your location. If your location is not listed
                                please email your information to this address: <a
                                    href="mailto:SHOpromotion@getrenegade.com?subject=%Showtime%20Spin%20Submission">SHOpromotion@getrenegade.com</a>.
                            </p>
                            <div class="w-full flex">
                                <div class="w-2/5 mx-3">
                                    <label class="text-2xl mb-1 block mt-2" for="company">Company</label>
                                    <select type="select"
                                        class="form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl"
                                        onchange="setUpStates()" name="company" id="company" value="">
                                        <!--                                        @if($client=="att")
                                            <option value="none">Choose Your Country</option>
                                            @else -->
                                        <option value="none">Choose Your Company</option>
                                        <!-- @endif -->
                                    </select>
                                </div>
                                <div class="w-1/5 mx-3">
                                    <label class="text-2xl mb-1 block mt-2" for="state">State</label>
                                    <select type="select"
                                        class="disabled form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl requiredField"
                                        disabled="true" name="state" onchange="setupAddress()" id="state" value="">
                                        <option value="none">ST</option>
                                    </select>
                                </div>
                                <div class="w-2/5 mx-3">
                                    <label class="text-2xl mb-1 block mt-2" for="address">Address</label>
                                    <select type="select"
                                        class="disabled form-select-input appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-2 px-4 pr-8 rounded leading-tight text-2xl requiredField"
                                        disabled="true" name="address" id="address" onchange="selectAddress()" value="">
                                        <option value="none">Address</option>
                                    </select>
                                    <!-- ROW// -->
                                </div>
                            </div>

                        </div>
                        <div class="w-11/12 mx-auto">
                            <div class="mt-1">
                                <hr class="w-1/2 mx-auto my-8" style="border-color:#2b8895;">
                                <div class="flex">
                                    <div class="w-1/2">
                                        <div class="mr-3 pb-3 pt-1 custom-checkbox h-24" style=" float:left; clear:left">

                                            <input type="checkbox" class="w-8 h-8" id="emailOpt" checked name="emailOpt"
                                                value="1">
                                        </div>
                                        <div class="mb-3">
                                            <p class="pt-1 text-3xl leading-snug">Yes, I would like to receive emails
                                                about incentive promotions and engagement opportunities from SHOPlace.
                                            </p>
                                        </div>
                                        <!-- <div>
                                            <div class="mr-3 pb-3 pt-1 custom-checkbox h-24" style=" float:left; clear:left">
                                                <input type="checkbox" class="w-8 h-8" id="displayName" checked name="displayName" value="1">
                                            </div>
                                            <div>
                                                <p class="pt-1 text-3xl leading-snug">Yes, please post my name on the Wall of Fame.</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="w-1/2">
                                        <p class="text-2xl " style="padding-bottom: 3px;">Please send my SHOWTIME
                                            updates to:</p>
                                        <input type="text" name="updatesEmail" id="updatesEmail"
                                            class="requiredField form-text-input shadow appearance-none border rounded w-full pt-3 pb-2 px-3 text-gray-700 leading-tight text-2xl">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-11/12 mx-auto mt-6">
                            <div class="w-1/2 mx-auto">
                                <input type="submit" value="SUBMIT" id="form-submit" disabled="disabled"
                                    class="block font-bold bg-gray-700 text-2xl h-18 flex mx-auto px-16 pt-4 pb-3 disabled-btn" />
                                <!-- <p class="text-center text-2xl mt-4">You must click "SUBMIT" to enter.</p> -->
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showForm: true,
        }
    },
    mounted() {
        setTimeout(() => {
            setupCompanies()
        }, 1000)

        // fix for form height
        let appFix = document.querySelector('#app')
        let formFix = document.querySelector('.main-screen.form')
        if (formFix) {
            appFix.style = 'min-height: 700px;'
        } else {
            appFix.style = 'min-height: 600px;'
        }
        //

    },
    methods: {
        sendData(event) {
            event.preventDefault()
            event.stopPropagation()
            processForm(this.score)
            return false
        },
        goSho() {
            this.client == 'comcast'
                ? window.open('https://www.sho.com/shoplace/comcast')
                : window.open('https://www.sho.com/shoplace/showtime')
        },

        playAgain() {
            this.$store.commit('handleView', 'restart')
            this.$root.confirmation = false
        },
        openSurvey() {
            window.open('https://www.surveymonkey.com/r/K72WJVT')
        }
    },
    computed: {
        randomCode: function () {
            var text = ''
            var possible = 'PERFCTAIS123456789'

            for (var i = 0; i < 4; i++)
                text += possible.charAt(
                    Math.floor(Math.random() * possible.length)
                )
            return 'HS' + text + Math.round(Math.random()) + '2'
        },
        confirmation() {
            // return true
            return this.$root.confirmation
        },
        client() {
            return this.$root.client
        },
        score() {
            return this.$store.state.score
        },
    },
}
</script>
<style scoped>
.bgblack {
    background-color: rgb(0 0 0 / 92%);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.checkbox-wrapper {
    float: left;
    width: 15px;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    background: #2b8895;
}

footer {
    flex-direction: column;
    justify-self: flex-end;
    padding: 2rem 2rem 0;
    margin: 2rem 2rem 1rem
}

footer p {
    text-align: center;
    width: 100%;
    font-size: 2.25rem;
    font-weight: 700;
    color: white;
    line-height: 1.5;
    border: 3px solid teal;
    padding: 1rem 0 0.5rem 0;
}

button {
    background-color: unset;
}

button.btn-red {
    background: #e20000;
}

button:hover {
    background: #444;
}
</style>
