<template lang='pug'>
.video-screen
    section
        .skip(@click='handleView') ×
        .video
            iframe(src='https://player.vimeo.com/video/873553227?loop=false&byline=false&portrait=false&title=false&speed=false&transcript=false&pip=false&transparent=0&gesture=media&muted=1&autoplay=1' allowfullscreen allowtransparency muted autoplay allow='autoplay')
    footer
        //- .skip(v-if='vidProgress > 6' @click='handleView') skip video
        //- .vidProgress(:style='length')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    mounted() {
        const iframe = document.querySelector('iframe')
        const player = new Vimeo.Player(iframe)
        player.setVolume('0.75')
        player.on('timeupdate', (vid) => { this.handleProgress(vid) })
    },
    computed: {
        ...mapGetters({ data: 'howto' }),
        ...mapState(['view', 'vidProgress']),
        client() { return this.$root.client },
        length() { return { width: `${this.vidProgress + 2}%` } }
    },
    methods: {
        handleView() {
            this.$store.commit('handleView', 'question')
            this.$store.commit('handleFreeze', { val: true, time: 1500 })
        },
        handleProgress(vid) {
            vid.percent >= 0.905 && this.handleView()
            this.$store.commit('setVidProgress', Math.round(vid.percent * 100))
            console.log(Math.round(vid.percent * 100))
            // this.$store.commit('handlePlay', 'vidProgress')
        }

    }
}
</script>

<style lang='sass' scoped>
.video-screen
    @include flex-center
    flex-flow: column
    justify-content: stretch
    width: 100%
    height: 100%
    background: rgb(0 0 0 / 90%)
    z-index: 1
    section, footer
        width: 100%
        height: 100%
    section
        .skip
            position: absolute
            top: -6px
            right: 8px
            font-size: 3.5rem
            letter-spacing: 0.05rem
            text-transform: uppercase
            cursor: pointer
            z-index: 10
            opacity: 0.5
            transition: opacity 0.25s
            &:hover
                opacity: 0.75
        .video
            position: absolute
            width: 100%
            height: 100%
            top: -34px
            // height: 0
            // padding-bottom: 56.25%
            iframe
                order: 0
                height: 100%
                left: 0
                position: absolute
                top: 0
                user-select: none
                width: 100%
    footer
        display: flex
        align-items: flex-end
        .skip
            position: absolute
            bottom: 30px
            left: 15px
            font-size: 1.5rem
            letter-spacing: 0.05rem
            text-transform: uppercase
            text-decoration: underline
            text-underline-offset: 0.25rem
            cursor: pointer
            &:hover
                opacity: 0.85
                transition: opacity 0.25s
        .vidProgress
            height: 10px
            width: 0%
            margin: 12px
            background: $sho-teal
            // transition: width 0.25s
</style>