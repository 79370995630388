<template lang="pug">
.intro-screen
    header
        .logo
            img(src='/imgs/app/tt-logo.png')
    section
        .copy
            p(v-html='data.copy')
            p(v-html='client === "comcast" ? data.copy2b : data.copy2a')
            button(@click='handleView') {{ data.button }}
    footer
        p {{ data.callout }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({ data: 'intro' }),
        ...mapState(['view']),
        client() { return this.$root.client }
    },
    methods: {
        handleView() {
            this.$store.commit('handleView', 'howto')
            this.$store.commit('handleFreeze', { val: true, time: 1000 })
        }
    }
}
</script>

<style lang="sass" scoped>
.intro-screen
    @include flex-center
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: space-between
    background-color: rgb(0 0 0 / 50%)
    &:before
        @include shadow2
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    header, section, footer
        z-index: 2
    header
        height: 28%
        .logo
            padding-top: 3rem
            width: 64%
    section
        color: white
        letter-spacing: 0.00025em
        font-size: 2.15rem
        line-height: 1.4
        text-align: left
        padding-right: 2rem
        .copy
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            text-align: center
            width: 78%
            p
                text-shadow: 0 0 2px rgb(0 0 0)
                margin-bottom: 2rem
    footer
        flex-direction: column
        justify-self: flex-end
        padding: 2rem
        p
            width: 100%
            font-size: 2.15rem
            font-weight: 600
            color: white
            line-height: 1.5
            border: 3px solid $sho-teal
            padding: 1rem 0
    > *
        @include flex-center
        width: 100%
</style>
