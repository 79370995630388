<template lang="pug">
main.main-screen(:class='[{freeze}, view]')
    .container
        transition(name='fade')
            IntroScreen(v-if='view === "intro"')
            VideoScreen(v-if='view === "video"')
            HowtoScreen(v-if='view === "howto"')
            QuestionScreen(v-if='view === "question"')
            ResultScreen(v-if='view === "result"')
            FormScreen(v-if='view === "form"')
    .over
    .top 
    .center
    .bottom
    //- .under
</template>

<script>
import { mapState } from 'vuex'
import { animations } from '../../gsap'
export default {
    mixins: [animations],
    mounted() {
        this.$store.commit('preloadImages', [])
    },
    computed: {
        ...mapState(['view', 'play', 'score', 'freeze'])
    },
    watch: {
        // watchers for animation
        play(val) {
            if (val === 'vidProgress') {
                this.playProgress()
            }
            setTimeout(() => {
                this.$store.commit('handlePlay', '')
            }, 1000)
        }
    }
}
</script>

<style lang="sass" scoped>
.main-screen
    @include full-inset
    color: white
    font-size: 4rem
    .top, .center, .bottom, .over, .under
        @include full-inset
        background-size: cover
    .top
        background-image: url(/imgs/app/tt-bg-film.jpg)
        z-index: 2
        width: 100%
        height: 33%
        top: 0
        left: 0
        background-position: 0 0
    .center
        background-image: url(/imgs/app/tt-bg-film.jpg)
        z-index: 2
        width: 100%
        height: 33%
        top: 34%
        left: 0
        background-position: -25% 0
    .bottom
        background-image: url(/imgs/app/tt-bg-film.jpg)
        z-index: 2
        width: 100%
        height: 33%
        top: 68%
        left: 0
        background-position: 46% 0
    .over
        // background-image: url(/imgs/app/ayh-bg-over.png)
        background-color: rgb(0 0 0 / 60%)
        z-index: 4
    .under
        background-image: url(/imgs/app/ayh-bg-under.jpg)
        z-index: 1
    .dev
        position: absolute
        bottom: 10px
        right: 20px
        z-index: 10
        color: lime
        opacity: 0.5
        font-size: 3rem
        .clk
            cursor: pointer
.container
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0
    height: 100%
    width: 100%
    z-index: 10
</style>
