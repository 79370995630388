import gsap from "gsap"
import CustomEase from "gsap/CustomEase"
gsap.registerPlugin(CustomEase)
const spinEase = 'M0,0,C0.202,0,0.338,0.491,0.448,0.65,0.586,0.85,0.9,1,1,1'
const spinEaseBack = 'M0,0,C0.073,0,0.142,-0.09,0.2,0,0.301,0.157,0.355,0.518,0.434,0.65,0.508,0.774,0.574,0.879,0.692,0.956,0.824,1.042,1,1,1,1'
const introEase = 'sine.in'

export const animations = {
    mounted() {
        this.$store.commit('handleFreeze', { val: true, time: 3000 })
        this.playIntro()
        this.playFilm('play')
    },
    methods: {

        playProgress() {
            gsap.to('.vidProgress', {
                width: '100%',
                duration: 58,
                ease: 'linear'
            })
        },
        playIntro() {
            gsap.set('.intro-screen, .intro-screen button, .logo', { opacity: 0 })
            gsap.set('.logo .text', { opacity: 0, rotate: -22.5 })
            const tl = gsap.timeline({ delay: 2 }).timeScale(1.25)
            tl.to('.intro-screen, .intro-screen button, .logo', { opacity: 1, duration: 1 })
              .set('.intro-screen', { clearProps: 'opacity' })
        },
        playFilm(val) {
            // const tl = gsap.timeline({paused: true, repeat: -1, yoyo: true})
            const tl = gsap.timeline({paused: true})
            tl.to('.main-screen .top', {backgroundPosition: '150% 0', duration: 2, ease: 'back.inOut'})
              .to('.main-screen .center', {backgroundPosition: '-200% 0', duration: 2, ease: 'back.inOut'}, '<')
              .to('.main-screen .bottom', {backgroundPosition: '250% 0', duration: 2, ease: 'back.inOut'}, '<')
            //   .to('.main-screen .top', {backgroundPosition: '70% 0', duration: 3, ease: 'back.inOut'}, '< +1.5')
            //   .to('.main-screen .center', {backgroundPosition: '-110% 0', duration: 3, ease: 'back.inOut'}, '< +1.5')
            //   .to('.main-screen .bottom', {backgroundPosition: '110% 0', duration: 3, ease: 'back.inOut'}, '< +1.5')
            if(val === 'play') {
                tl.play()
            } 
            if(val === 'stop') {
                tl.pause()
                // gsap.set('.main-screen .top, .main-screen .center, .main-screen .bottom', { clearProps: 'background-position' })
            }
            
        },
        resetFilm() {
            this.playFilm('stop')
        },
        playLess() {
            const tl = gsap.timeline()
            tl.to('.logo .scale .arm', { rotate: -20, y: 20, x: 2, duration: 1, ease: introEase })
                .to('.logo .scale .left', { y: 20, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: -30, x: -5, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .arm', { rotate: 0, y: 0, x: 0, duration: 1, ease: introEase }, '+1.5')
                .to('.logo .scale .left', { y: 0, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: 0, x: 0, duration: 1, ease: introEase }, '<')
                .to('.question-screen .over', { opacity: 1, duration: 1 }, '<')
                .to('.question-screen .over', { opacity: 0, duration: 1 }, '> -0.25')
        },
        playMore() {
            const tl = gsap.timeline()
            tl.to('.logo .scale .arm', { rotate: 20, y: -30, x: 7, duration: 1, ease: introEase })
                .to('.logo .scale .left', { y: -30, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: 20, x: -5, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .arm', { rotate: 0, y: 0, x: 0, duration: 1, ease: introEase }, '+1.5')
                .to('.logo .scale .left', { y: 0, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: 0, x: 0, duration: 1, ease: introEase }, '<')
                .to('.question-screen .over', { opacity: 1, duration: 1 }, '<')
                .to('.question-screen .over', { opacity: 0, duration: 1 }, '> -0.25')
        },
        playResultLess() {
            const tl = gsap.timeline()
            tl.to('.logo .scale .arm', { rotate: -20, y: 20, x: 2, duration: 1, ease: introEase })
                .to('.logo .scale .left', { y: 20, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: -30, x: -5, duration: 1, ease: introEase }, '<')
        },
        playResultMore() {
            const tl = gsap.timeline()
            tl.to('.logo .scale .arm', { rotate: 20, y: -30, x: 7, duration: 1, ease: introEase })
                .to('.logo .scale .left', { y: -30, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: 20, x: -5, duration: 1, ease: introEase }, '<')
        },
        playResultNone() {
            gsap.set('.logo .scale .arm', { rotate: -20, y: 20, x: 2, duration: 1, ease: introEase })
            gsap.set('.logo .scale .left', { y: 20, duration: 1, ease: introEase }, '<')
            gsap.set('.logo .scale .right', { y: -30, x: -5, duration: 1, ease: introEase }, '<')
            const tl = gsap.timeline()
            tl.to('.logo .scale .arm', { rotate: 0, y: 0, x: 0, duration: 1, ease: introEase })
                .to('.logo .scale .left', { y: 0, duration: 1, ease: introEase }, '<')
                .to('.logo .scale .right', { y: 0, x: 0, duration: 1, ease: introEase }, '<')
        },

    }

}