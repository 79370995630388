<template lang="pug">
.result-screen
    header
        .logo
            img(src='/imgs/app/tt-logo.png')
    section
        .copy(v-if='win')
            p
                strong(v-html='data.win.title')
            p(v-html='client === "comcast" ? data.win.copy2b : data.win.copy2a')
            p(v-html='data.win.copy')
        .copy(v-else)
            p
                strong(v-html='data.lose.title')
            p(v-html='data.lose.copy')
            p(v-html='client === "comcast" ? data.lose.copy2b : data.lose.copy2a')
    footer
        .buttons(v-if='win')
            button(@click='handleView("register")') Register To Win
            button(@click='handleView("restart")') Play Again
        .buttons(v-else)
            button(@click='handleView("restart")') Play Again
        p(v-html='data.win.callout')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({ data: 'result' }),
        ...mapState(['view', 'score']),
        client() { return this.$root.client },
        win() { return this.score >= 4 }
    },
    methods: {
        handleView(val) {
            val === 'register' && this.$store.commit('handleView', 'form')
            val === 'restart' && this.$store.commit('handleView', 'restart')
        }
    }
}
</script>

<style lang="sass" scoped>
.result-screen
    @include flex-center
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: space-between
    background-color: rgb(0 0 0 / 50%)
    &:before
        @include shadow2
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    header, section, footer
        z-index: 2
    header
        height: 28%
        .logo
            padding-top: 3rem
            width: 64%
    section
        color: white
        letter-spacing: 0.00025em
        font-size: 2.15rem
        line-height: 1.4
        text-align: left
        padding-right: 2rem
        .copy
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            text-align: center
            width: 78%
            margin-top: 1rem
            p
                text-shadow: 0 0 2px rgb(0 0 0)
                margin-bottom: 0.5rem
                strong
                    font-weight: 500
                    text-transform: uppercase
    footer
        flex-direction: column
        justify-self: flex-end
        padding: 2rem
        p
            width: 100%
            font-size: 2.25rem
            font-weight: 600
            color: white
            line-height: 1.5
            border: 3px solid $sho-teal
            padding: 1rem 0 0.5rem 0
        .buttons
            margin: 1rem 0 4rem 0
            width: 100%
            button
                max-width: 32%
    > *
        @include flex-center
        width: 100%

</style>
