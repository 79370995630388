/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
import Vue from 'vue'

Vue.prototype.gtm = window.dataLayer

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import { store } from './store'

window.app = new Vue({
    el: '#app',
    store,
    data: {
        globalUrl: document.head.querySelector('meta[name="globalUrl"]')
            .content,
        client: document.head.querySelector('meta[name="client"]').content,
        email: '',
        confirmation: false
    },
    created() {},
    mounted() {
        let customEase = document.createElement('script')
        customEase.setAttribute('src', '../dist/CustomEase.min.js')
        document.head.appendChild(customEase)
        let vimeoPlayer = document.createElement('script')
        vimeoPlayer.setAttribute('src', '../dist/player.js')
        document.head.appendChild(vimeoPlayer)
    },
    computed: {
        isIE: function() {
            if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
                return true
            } else {
                return false
            }
        }
    }
})
